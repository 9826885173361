<template>
  <Layout>
    <PageHeader
      :title="$route.name == 'add-clinic' ? title1 : title2"
      :items="items"
    ></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <div class="row">
                <b-form-group
                  id="input-group-1"
                  label-for="input-1"
                  class="col-6"
                >
                  <label style="font-weight: 500"
                    >Title <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-1"
                    placeholder="Enter Title"
                    v-model="form.title"
                    @keyup.prevent="slugify"
                    :class="{
                      'is-invalid': submitted && $v.form.title.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.title.required"
                    class="invalid-feedback"
                  >
                    Title is required.
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label-for="input-1"
                  class="col-6"
                >
                  <label style="font-weight: 500"
                    >Url Link <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-1"
                    placeholder="Enter Title"
                    v-model="form.url_link"
                    :disabled="$route.name === 'edit' || !!form.url_link"
                    :class="{
                      'is-invalid': submitted && $v.form.url_link.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.url_link.required"
                    class="invalid-feedback"
                  >
                    Url Link is required.
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-group-2"
                  label-for="input-2"
                  class="col-6"
                >
                  <label style="font-weight: 500"
                    >Sub Title <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-2"
                    placeholder="Enter Sub Title"
                    v-model="form.sub_title"
                    :class="{
                      'is-invalid': submitted && $v.form.sub_title.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.sub_title.required"
                    class="invalid-feedback"
                  >
                    Sub Title is required.
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label-for="input-3"
                  class="col-6"
                >
                  <label style="font-weight: 500">Latitude</label>
                  <b-form-input
                    id="input-3"
                    type="number"
                    placeholder="Enter Latitude"
                    v-model="form.latitude"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-4"
                  label-for="input-4"
                  class="col-6"
                >
                  <label style="font-weight: 500">Longitude</label>
                  <b-form-input
                    id="input-4"
                    type="number"
                    placeholder="Enter Longitude"
                    v-model="form.langitude"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-6">
                  <label for="case_ques_type"
                    >Type <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="case_ques_type"
                    v-model="form.type"
                    value-field="value"
                    text-field="text"
                    :options="typeOpt"
                    :class="{
                      'is-invalid': submitted && $v.form.type.$invalid,
                    }"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.type.required"
                    class="invalid-feedback"
                  >
                    Type is required.
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-group-5"
                  label-for="input-5"
                  class="col-6"
                >
                  <label style="font-weight: 500">Mobile Number</label>
                  <b-form-input
                    id="input-5"
                    type="number"
                    max="10"
                    placeholder="Enter Mobile Number"
                    v-model="form.mobile_number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-6"
                  label-for="input-6"
                  class="col-6"
                >
                  <label style="font-weight: 500">Email</label>
                  <b-form-input
                    id="input-6"
                    type="email"
                    placeholder="Enter Email"
                    v-model="form.email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Languages"
                  label-for="input-lang"
                  class="col-6"
                >
                  <multiselect
                    id="input-lang"
                    v-model="form.languages"
                    :options="allLanguages"
                    :multiple="true"
                    track-by="value"
                    label="label"
                  >
                  </multiselect>
                </b-form-group>
                <b-form-group
                  label="Banner Image [Upload Max File Size : 2MB]"
                  label-for="image_name"
                  class="col-6"
                >
                  <b-form-file
                    id="image_name"
                    accept=".jpg,.jpeg,.png,.svg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'banner_image')"
                    ref="banner_image"
                  >
                  </b-form-file>
                  <template v-if="edit.banner_image_url">
                    <img
                      :src="edit.banner_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  label="Logo Image [Upload Max File Size : 2MB]"
                  label-for="logo_img"
                  class="col-6"
                >
                  <b-form-file
                    id="logo_img"
                    accept=".jpg,.jpeg,.png,.svg"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'logo_image')"
                    ref="logo_image"
                  >
                  </b-form-file>
                  <template v-if="edit.logo_image_url">
                    <img
                      :src="edit.logo_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  id="input-group-7"
                  label-for="input-7"
                  class="col-12"
                >
                  <label style="font-weight: 500"
                    >Description <span style="color: red">*</span></label
                  >
                  <ckeditor
                    v-model="form.description"
                    :editor="editor"
                    :class="{
                      'is-invalid': submitted && $v.form.description.$invalid,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.form.description.required"
                    class="invalid-feedback"
                  >
                    Description is required.
                  </div>
                </b-form-group>
                <b-form-group
                  id="input-group-8"
                  label-for="input-8"
                  class="col-12"
                >
                  <label style="font-weight: 500">Address </label>
                  <b-form-textarea
                    id="input-7"
                    rows="5"
                    v-model="form.address"
                    placeholder="Enter Address"
                  >
                  </b-form-textarea>
                </b-form-group>
              </div>
              <template v-if="$route.name == 'edit-clinic'">
                <h6 class="mt-4">Clinic Tabs:</h6>
                <div v-for="(data, i) in clinic_tabs" :key="i">
                  <label>Label for {{ i }} :</label>
                  <div class="row">
                    <div class="col-md-4">
                      <b-form-group label-for="case">
                        <b-form-input
                          id="case"
                          type="number"
                          v-model="data.index"
                          placeholder="Index"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-4">
                      <b-form-group
                        id="input-group-1"
                        label-for="input-1"
                        class="mb-0"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="data.label"
                          placeholder="Enter Label"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div
                      class="col-md-4"
                      style="
                        display: flex;
                        align-items: start;
                        padding-top: 10px;
                      "
                    >
                      <div style="display: flex; gap: 10px">
                        <b-form-checkbox v-model="data.active"
                          >Active</b-form-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
                ><span v-if="this.$route.name == 'add-clinic'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
            <div
              class="my-4 d-flex justify-content-start align-items-center"
              v-if="$route.name == 'edit-clinic'"
            >
              <h6
                style="font-weight: 800"
                class="mr-2 mb-0"
                v-if="can('clinic-social-link')"
              >
                SOCIAL LINKS :
              </h6>
              <router-link
                :to="{
                  name: 'edit-social-links',
                  params: { id: $route.params.id },
                }"
                v-if="can('clinic-social-link')"
              >
                <button class="btn btn-danger d-flex align-items-center btn-sm">
                  <i class="mdi mdi-link mr-1"></i>
                  <span class="fw-bold"> Social Link </span>
                </button>
              </router-link>
            </div>
            <template v-if="$route.name == 'edit-clinic'">
              <HospitalTimings :type="form.type" v-can="'clinic-timings'" />
              <Services :type="form.type" v-can="'clinic-services'" />
              <Amenity :type="form.type" v-can="'clinic-amenity'" />
              <Faq :type="form.type" v-can="'clinic-faqs'" />
              <ClinicGallery :type="form.type" v-can="'clinic-gallery'" />
              <MemberTagging :type="form.type" v-can="'clinic-members'" />
              <PatientStories
                :type="form.type"
                v-can="'clinic-patient-stories'"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import clinicMixin from "@/mixins/ModuleJs/clinic";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { required } from "vuelidate/lib/validators";
import HospitalTimings from "@/components/hospital/HospitalTimings";
import Services from "@/components/hospital/Services";
import Amenity from "@/components/hospital/Amenity";
import Faq from "@/components/hospital/Faq";
import ClinicGallery from "@/components/hospital/ClinicGallery";
import MemberTagging from "@/components/hospital/MemberTagging";
import PatientStories from "@/components/hospital/PatientStories";

export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    HospitalTimings,
    Services,
    Amenity,
    Faq,
    ClinicGallery,
    MemberTagging,
    PatientStories,
  },
  mixins: [MixinRequest, clinicMixin],
  data() {
    return {
      editor: ClassicEditor,
      submitted: false,
      title1: "Add Clinic",
      title2: "Edit Clinic",
      items: [
        {
          text: "Back",
          href: "/clinic",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      title: { required },
      sub_title: { required },
      description: { required },
      type: { required },
      url_link: { required}
    },
  },
};
</script>
